import { useInView } from 'react-intersection-observer';
import classes from './TextSection.module.css'

function TextSection({children, backgroundColor}) {
  const textSectionClasses = [];
  const { ref, inView } = useInView({
    threshold: 0,
  });

  if (inView) {
    textSectionClasses.push(classes.inView);
  }

  if (backgroundColor) {
    textSectionClasses.push(`fds-color__bg--${backgroundColor}`);
  }

  return (
    <div ref={ref} className={`fds-layout-grid ${classes.textSection} ${textSectionClasses.join(' ')}`}>
        <div className={`fds-layout-grid__inner`}>
            <div className={`fds-layout-grid__cell--span-8`}>
                {children}
            </div>
        </div>
    </div>
  )
}
export default TextSection;
