import React, { useEffect, useRef, useState } from "react";
import classes from './Video.module.css'

function Video({videoSrc}) {
  const videoRef = useRef(null);
  const [ inView, setInView ] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // means "viewport"
      rootMargin: "0px",
      threshold: 0.2,
    }

    const observerCallback = (entries) => {
      // console.log('entries', entries);
      const [entry] = entries;
      setInView(entry.isIntersecting);
    }

    const observer = new IntersectionObserver(observerCallback, options);
    const video = videoRef.current;
    if (video) { observer.observe(video) }

    return () => {if (video) { observer.unobserve(video) }}
  }, [videoRef])

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (inView) {
        video.play()
      } else {
        video.pause()
      }
    }
  }, [inView, videoRef])

  return (
    <video ref={videoRef} className={classes.video} src={videoSrc} muted loop playsInline preload="auto"></video>
  )
}

export default Video;
