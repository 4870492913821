import { tns } from "tiny-slider/src/tiny-slider";

function carouselBootstrap(carousel, currentSlide) {

  // console.log('bootstrapping carousel', carousel);

  const slider = tns({
    container: carousel,
    items: 1,
    slideBy: 1,
    autoplay: false,
    loop: false
  });

  const sliderInfo = slider.getInfo();
  const container = carousel.closest('.js-fmc-carousel-container');
  const prevButton = container.querySelector('.fmc-carousel-indicator__directional-button--previous');
  const nextButton = container.querySelector('.fmc-carousel-indicator__directional-button--next');
  const paginationText = container.querySelector('.fmc-carousel-indicator__pagination-text');

  const activeSlide = (active) => {
    const pancakes = container.querySelectorAll('.fmc-carousel-indicator__pancake-buttons li');
    const previous = container.querySelector('.fmc-carousel-indicator__directional-button--previous');
    const next = container.querySelector('.fmc-carousel-indicator__directional-button--next');

    if (pancakes) {
      pancakes.forEach((pancake, index) => {
        pancake.classList.remove('fmc-carousel-indicator__button--active');

        pancake.querySelector('button').addEventListener('click', () => {
          currentSlide = index + 1;
          goToSlide(currentSlide);
          activeSlide(currentSlide);
        });

        if (index === active - 1) {
          pancake.classList.add('fmc-carousel-indicator__button--active');
        }
      });
    }

    if (active <= 1) {
      previous.classList.add('fmc-carousel-indicator__button--disabled');
      previous.setAttribute('disabled', true);
    } else {
      previous.classList.remove('fmc-carousel-indicator__button--disabled');
      previous.removeAttribute('disabled');
    }

    if (active >= sliderInfo.pages) {
      next.classList.add('fmc-carousel-indicator__button--disabled');
      next.setAttribute('disabled', true);
    } else {
      next.classList.remove('fmc-carousel-indicator__button--disabled');
      next.removeAttribute('disabled');
    }

    if (paginationText) {
      paginationText.innerHTML = `${active} / ${sliderInfo.pages}`;
      paginationText.setAttribute('aria-label', `You are currently on Slide ${active} of ${sliderInfo.pages}`);
    }
  }

  const goToSlide = (slide) => {
    slider.goTo(slide - 1);
  }

  prevButton.addEventListener('click', () => {
    if (currentSlide > 1) {
      currentSlide = currentSlide - 1;
      goToSlide(currentSlide);
      activeSlide(currentSlide);
    }
  });

  nextButton.addEventListener('click', () => {
    if (currentSlide < sliderInfo.pages) {
      currentSlide = currentSlide + 1;
      goToSlide(currentSlide);
      activeSlide(currentSlide);
    }

  });

  goToSlide(currentSlide);
  activeSlide(currentSlide);

}

export default carouselBootstrap;
