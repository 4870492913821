import { useState } from "react"
import LightningPage from "./LightningPage"
import Password from "./Password"

function App() {
  const [showPage, setShowPage] = useState(false)

  return (
    <>
      {!showPage && <Password setShow={setShowPage}/>}
      {showPage && <LightningPage />}
    </>
  )
}

export default App
