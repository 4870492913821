import { useState } from 'react'
import classes from './Password.module.css'

function Password({setShow}) {
  const [inputValue, setInputValue] = useState('');

  const checkPassword = () => {
    if (inputValue === 'test') {
      setShow(true);
    } else {
      setInputValue('')
    }
  }

  const handleChange = e => {
    setInputValue(e.target.value)
  }

  return (
      <form className={`fmc-pa-7 ${classes.passwordContainer}`} onSubmit={checkPassword}>
        <input type="password" className="fmc-input" value={inputValue} onChange={handleChange}/>
        <button className="fmc-button fmc-ml-4" type='submit'>Submit</button>
      </form>
  )
}

export default Password
