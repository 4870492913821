export const generateThresholds = (numSteps = 20, min = 0, max = 1.0) => {
  const thresholds = [];

  if (min === 0) thresholds.push(0);

  for (let i=max; i<=numSteps; i++) {
    const ratio = i/numSteps;
    if (ratio > min ) {
      if (ratio < max) {
        thresholds.push(ratio);
      }
    }
  }

  return thresholds;
}

export const isiOS = (navigator.userAgent.includes("Mac") && "ontouchend" in document);

export const clamp01 = (x) => Math.max(0.0, Math.min(x, 1.0));
