import Header from './Header'
import Hero from './Hero'
import TextSection from './TextSection'
import TurntableSmooth from './TurntableSmooth'
import Detail from './Detail'
import DetailContainer from './DetailContainer'
import DoubleImage from './DoubleImage'
import Carousel from './Carousel'
import CarouselCard from './CarouselCard'
import Button from './Button';
import Video from './Video';
import SingleImage from './SingleImage'
import FadeOut from './FadeOut'

function LightningPage() {
  return (
    <>
      <Header />

      <Hero
        imgSrc="/images/22_FRD_F15_LIGHTNING_56319_Small.jpg"
        imgDimension = {{ x: 3500, y: 2333 }}
        startCoord = {{ scale: 3.25, x: 0, y: 0 }}  /* NOTE: x,y are percentages */
        endCoord = {{ scale: 1, x: 0, y: 0 }}
        transformOrigin = { "0% 100%" }
        endAspectRatio = { 3/2 }
      >
        <div className="fmc-type--heading5 hero-scrim">Introducing the</div>
        <div className="fmc-type--heading3 hero-scrim">F-150 Lightning</div>
        <div className="fmc-type--body1 fmc-mt-2 hero-scrim">Starting at $39,974, plus up to $7,500 in tax credits</div>
        <div className="fmc-mt-4">
          <Button darkBackground>Build &amp; Price</Button>
        </div>
      </Hero>

      <TextSection>
        <h3>Everything you imagined from an <b>all-electric future.</b></h3>
        <p className="fds-color__text--gray3">
          A new age for Ford F-150 is here.  Electric&nbsp;powered with your life in mind.
        </p>
      </TextSection>

      <TurntableSmooth />

      <DetailContainer backgroundColor={"fourth"}>
        <Detail transitionDelay="0.25s" >
          <h5>300 mi</h5>
          <p className="fds-color__text--gray3">Range</p>
        </Detail>
        <Detail transitionDelay="0.5s" >
          <h5>0 - 60 mph</h5>
          <p className="fds-color__text--gray3">In mid-4 Seconds</p>
        </Detail>
        <Detail transitionDelay="0.75s" >
          <h5>Under 8 hrs</h5>
          <p className="fds-color__text--gray3">Charge At Home</p>
        </Detail>
        <Detail transitionDelay="1.0s" >
          <h5>10,000 lbs</h5>
          <p className="fds-color__text--gray3">Towing Capacity</p>
        </Detail>
      </DetailContainer>

      <Video videoSrc="video/powering-home-story.mp4" />

      <TextSection>
        <h3>When the grid goes down, <b>keep the house powered up.</b></h3>
        <p className="fds-color__text--gray3">
          All the generator power you need to keep the house running from your truck for up to 10 days.
        </p>
        <Button chevron={"right"} textButton={true}>Intelligent Backup Power</Button>
      </TextSection>

      <DoubleImage highlightImg={'/images/frunk_1.jpg'} mainImg={'/images/F150_Lariat_990_Parking_Garage_Largest_Frunk_2656.jpg'} />

      <TextSection>
        <h5>Golf clubs, tools, 400lbs of concrete mix <b>- there's plenty of room up front.</b></h5> {/* NOTE: this is an H5 to make it smaller (24px) */}
        <p className="fds-color__text--gray3">
          With 14.1 cubic feet, it's the largest sealed front trunk of any all-electric truck.
        </p>
        <Button chevron={"right"} center={true}>Mega Power Frunk</Button>
      </TextSection>

      <Carousel>
        <CarouselCard imgSrc="/images/22_FRD_F15_BEV_53728.2022-02-16-104104-0.jpg" >
          <h3>Total Power</h3>
          <p>Targeted 563 horsepower, delivering 775lbs-ft. of torque</p>
          <Button darkBackground={true}>Learn More</Button>
        </CarouselCard>
        <CarouselCard imgSrc="/images/22_FRD_F15_LIGHTNING_56320.jpg" >
          <h3>Adventure Ready</h3>
          <p>Get off-road with standard 4x4&nbsp;performance</p>
          <Button darkBackground={true}>Learn More</Button>
        </CarouselCard>
        <CarouselCard imgSrc="/images/22_FRD_F15_LIGHTNING_56317.jpg">
          <h3>Independent Rear Suspension</h3>
          <p>Tested to live up to Built&nbsp;Ford&nbsp;Tough standards</p>
          <Button darkBackground={true}>Learn More</Button>
        </CarouselCard>
        <CarouselCard imgSrc="/images/22_FRD_F15_LIGHTNING_56318.jpg">
          <h3>Pro Power Onboard</h3>
          <p>9.6kW of power through&nbsp;11&nbsp;outlets</p>
          <Button darkBackground={true}>Learn More</Button>
        </CarouselCard>
      </Carousel>
      <FadeOut>
        <SingleImage imgSrc="/images/camping.png" /> {/* temporary */}
        <TextSection backgroundColor={"primary"}>
          <h3 className="fds-color__text--white">
            You do the driving. <b>Your&nbsp;truck does the&nbsp;learning.</b>
          </h3>
          <p className="fds-color__text--white">
            Advanced technology calculates payload, grade, even weather and traffic, to predict how much energy is used.
          </p>
          <Button isOutlined={true} darkBackground={true}>Intelligent Range</Button>
        </TextSection>
      </FadeOut>
    </>
  )
}

export default LightningPage
