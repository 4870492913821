function CarouselCard({children, imgSrc}) {

  return (
    <div className="fmc-billboard fmc-billboard--justify-center fmc-billboard--align-start">
        <img
          className="fmc-billboard__image fmc-carousel__image"
          src={imgSrc}
          alt="a placeholder"
        />
        <div className="fmc-billboard__scrim fmc-billboard--scrim-top fmc-billboard--scrim-dark"></div>
        <div className="fmc-billboard__content">
            {children}
        </div>
    </div>
  )
}

export default CarouselCard;
