import classes from './Button.module.css'

function Button({children, isOutlined, darkBackground, textButton, chevron, center}) {
    const buttonClasses = []
    let rightChevron, leftChevron
    if (textButton) {
        buttonClasses.push('fmc-text-button');
        if (chevron==="right") {
            buttonClasses.push('fmc-text-button--chevron-right');
        } else if (chevron==="left") {
            buttonClasses.push('fmc-text-button--chevron-left');
        }
    } else {
        buttonClasses.push('fmc-button');
        if (chevron==="right") {
            rightChevron = <span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right" />
        } else if (chevron==="left") {
            leftChevron = <span className="fds-icon fds-font--ford-icons__chevron-left fds-icon--offset-left" />
        }
    }
    if (isOutlined) {
        buttonClasses.push('fmc-button--outlined');
    }

    const button = (
        <button className={buttonClasses.join(' ')} data-dark={darkBackground}>
            {leftChevron}
            {children}
            {rightChevron}
        </button>
    )

    const element = center ? <div className={classes.centerButton}>{button}</div> : button;

    return element;
}

export default Button;
