import classes from './Header.module.css';
import {ReactComponent as FordLogoFilled} from './assets/images/ford-logo-filled.svg';
import MenuIcon from './assets/images/menu-icon.png';

function Header() {
  return (
    <div className={classes.header}>
      <FordLogoFilled className={classes.headerLogo} />
      <img alt="" src={MenuIcon} className={classes.menuIcon} />
    </div>
  )
}

export default Header;
