import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from 'react-intersection-observer';
import { clamp01 } from "./utilities";
import classes from './DoubleImage.module.css';

function DoubleImage({highlightImg, mainImg}) {
  const doubleImgRef = useRef(null);
  const [ inViewRef, inView ] = useInView({
    threshold: 0,
  });

  const [ scrollParams, setScrollParams ] = useState({});
  const [ scrollProgress, setScrollProgress ] = useState(0);
  const [ highlightShrinkProgress, setHighlightShrinkProgress ] = useState(0);

  useEffect(() => {
    // Runs only on initial render to calculate some parameters.
    const doubleImgRect = doubleImgRef.current.getBoundingClientRect();
    const doubleImgStyles = getComputedStyle(doubleImgRef.current);
    const scrollHeight = window.innerHeight + (doubleImgRect.bottom - doubleImgRect.top);
    const marginTop = parseFloat(doubleImgStyles.getPropertyValue('--margin-top'));
    setScrollParams({ scrollHeight, marginTop });
  }, [inView])

  const setRefs = useCallback((node) => {
    doubleImgRef.current = node;
    inViewRef(node);
  }, [inViewRef]);

  const handleScroll = useCallback(() => {
    const doubleImgRect = doubleImgRef.current.getBoundingClientRect();
    const progress = (scrollParams.scrollHeight - doubleImgRect.bottom)/scrollParams.scrollHeight;
    const shrinkProgress = (doubleImgRect.top - scrollParams.marginTop) / (- window.innerHeight / 2 - scrollParams.marginTop);
    setScrollProgress(clamp01(progress));
    setHighlightShrinkProgress(clamp01(shrinkProgress));
  }, [ scrollParams ]);

  useEffect(() => {
    if (inView) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [inView, handleScroll]);

  // useEffect(() => {
  //   console.log('progress',
  //               scrollProgress.toFixed(3),
  //               highlightShrinkProgress.toFixed(3),
  //               scrollParams
  //   )
  // }, [scrollProgress, highlightShrinkProgress, scrollParams])

  const doubleImageStyle = { '--scroll-factor': scrollProgress, '--highlight-shrink-progress': highlightShrinkProgress};

  return (
    <div ref={setRefs} className={classes.doubleImage} style={doubleImageStyle}>
      <div className={classes.highlightImg} style={{backgroundImage: `url(${highlightImg})`}} />
      <div className={classes.mainImg}>
        <img src={mainImg} alt="" />
      </div>
    </div>
  )
}

export default DoubleImage;
