import classes from './DetailContainer.module.css'

function Detail({children, transitionDelay = 0}) {
  const style = {"--transition-delay": transitionDelay};
  return (
    <div className={`fds-layout-grid__cell--span-2 ${classes.detail}`} style={style}>
        {children}
    </div>
  )
}

export default Detail;
