import React, { useCallback, useEffect, useRef } from "react";
import { useInView } from 'react-intersection-observer';
import carouselBootstrap from './carouselBootstrap.js'
import './Carousel.css'

function Carousel({children, currentSlide = 1}) {
  const carouselRef = useRef(null);
  const [ inViewRef, inView ] = useInView({
    threshold: 0,
  });

  const bootstrapCarousel = useCallback(() => {
    carouselBootstrap(carouselRef.current, currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    setTimeout(bootstrapCarousel, 300);
  }, [bootstrapCarousel]);

  const carouselInViewClasses = [];

  if (inView) {
    carouselInViewClasses.push('carousel-in-view');
  }

  const nSlides = children.length;
  const slides = children.map((child, index) => (
    <li key={`slide-${index+1}`} aria-label="a placeholder">
      {child}
    </li>
  ))
  const carousel = <ul ref={carouselRef} className="fmc-carousel">{slides}</ul>

  let pagination
  if (nSlides <= 4) {
    // Make pancakes
    const pancakes = children.map((child, index) => (
      <li key={`pancake-${index+1}`}>
        <button
          className="fmc-carousel-indicator__pancake-button"
          aria-label={`Click or press enter to view ${index + 1} of ${nSlides}`}
        >
          <span className="fmc-carousel-indicator__pancake"></span>
        </button>
      </li>
    ))
    pagination = <ul className="fmc-carousel-indicator__pancake-buttons">{pancakes}</ul>
  } else {
    pagination = (
      <div
        className="fmc-carousel-indicator__pagination-text"
        tabIndex="0"
        aria-label={`You are currently on Slide ${currentSlide} of ${nSlides}`}
      >
        {currentSlide} / {nSlides}
      </div>
    )
  }

  const carouselIndicator = <div className="fmc-carousel-indicator">
    <button
      className="fmc-carousel-indicator__directional-button fmc-carousel-indicator__directional-button--previous"
      aria-label="previous button"
    ></button>
    <div className="fmc-carousel-indicator__pagination"> {pagination} </div>
    <button
      className="fmc-carousel-indicator__directional-button fmc-carousel-indicator__directional-button--next"
      aria-label="next button"
    ></button>
  </div>


  return (
  <div ref={inViewRef} className="carousel-wrapper">
    <div
      className={`js-fmc-carousel-container fmc-carousel-container lightning-demo-carousel ${carouselInViewClasses.join(' ')}`}
    >
      {carousel}
      {carouselIndicator}
    </div>
  </div>
  )
}

export default Carousel;
