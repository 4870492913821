import { useInView } from 'react-intersection-observer';
import classes from './DetailContainer.module.css'

function DetailContainer({children, backgroundColor}) {
  const detailContainerClasses = []
  const { ref, inView } = useInView({
    threshold: 0,
  });

  if (inView) {
    detailContainerClasses.push(classes.inView);
  }

  if (backgroundColor) {
    detailContainerClasses.push(`fds-color__bg--${backgroundColor}`)
  }

  return (
    <div ref={ref} className={`fds-layout-grid ${classes.detailContainer} ${detailContainerClasses.join(' ')}`}>
        <div className={`fds-layout-grid__inner`}>
            {children}
        </div>
    </div>
  )
}
export default DetailContainer;
