import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from 'react-intersection-observer';
import './Hero.css';

function Hero({children, imgSrc, imgDimension, startCoord, endCoord, transformOrigin, endAspectRatio}) {
  const billboardRef = useRef(null);
  const [ inViewRef, inView ] = useInView({ threshold: 0});
  const setRefs = useCallback((node) => {
    billboardRef.current = node;
    inViewRef(node);
  }, [inViewRef]);

  const [ heightLimits, setHeightLimits ] = useState({});
  const [ progress, setProgress ] = useState(0);

  useEffect(() => {
    // Runs only on initial render to calculate the height limits.
    const billboardRect = billboardRef.current.getBoundingClientRect();
    const billboardHeight = billboardRect.bottom - billboardRect.top,
          billboardWidth = billboardRect.right - billboardRect.left;
    setHeightLimits({ start: billboardHeight, end: billboardWidth / endAspectRatio })
  }, [ endAspectRatio ])

  const handleScroll = useCallback(() => {
    const billboardRect = billboardRef.current.getBoundingClientRect();
    const progress = (heightLimits.start - billboardRect.bottom) / (heightLimits.start - heightLimits.end);
    setProgress(Math.max(0.0, Math.min(progress, 1.0)));
  }, [heightLimits]);

  useEffect(() => {
    if (inView) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [inView, handleScroll]);

  const transform = useMemo(() => {
    const lerp = (v0, v1, t) => {
      // Linear interpolation.
      return (1.0 - t) * v0 + t * v1;
    }

    // Consider using an easing function for one or more of the calculated parameters.
    // https://www.npmjs.com/package/js-easing-functions is a neat collection of easing functions.
    const scale = lerp(startCoord.scale, endCoord.scale, progress);
    const x = lerp(startCoord.x, endCoord.x, progress);
    const y = lerp(startCoord.y, endCoord.y, progress);

    return `scale(${scale}) translate(${x}%, ${y}%)`;
  }, [progress, startCoord, endCoord]);

  const heroStyle = {'--progress': progress.toFixed(4), '--hero-copy-scrim-opacity': Math.max((1.0 - progress*2), 0)};
  const imgStyle = { transform, transformOrigin };

  return (
    <div ref={setRefs} className="hero fmc-billboard fmc-billboard--justify-start fmc-billboard--align-end fmc-billboard--height-100vh" style={heroStyle}>
      <img className="hero__image" src={imgSrc} alt="" style={imgStyle} />
      <div className="fmc-billboard__scrim fmc-billboard--scrim-dark"></div>
      <div className="hero__content fmc-billboard__content fmc-px-4 fmc-py-12 fds-color__text--white">
        {children}
      </div>
    </div>
  )
}

export default Hero;
