import React, { useEffect, useCallback, useRef, useState } from "react";
import { useInView } from 'react-intersection-observer';
import { clamp01 } from "./utilities";
import './TurntableSmooth.css'

function TurntableSmooth() {

  const frameCount = 42; // No really
  const currentFrame = index => (`/images/F-150LightningGrayPlatinumGray_Rotate/0001${index.toString().padStart(2, '0')}.png`);
  const [frameIndex, setFrameIndex ] = useState(0);

  const canvasRef = useRef(null);
  const turntableRef = useRef(null);
  const [ inViewRef, inView ] = useInView({
    threshold: 0,
  });
  const setRefs = useCallback((node) => {
    turntableRef.current = node;
    inViewRef(node);
  }, [inViewRef]);

  const handleScroll = useCallback(() => {
    const turntableRect = turntableRef.current.getBoundingClientRect();
    const turntableCenter = (turntableRect.bottom + turntableRect.top)/2;
    const scrollStart = window.innerHeight * .80; // 20% up from bottom
    const scrollEnd = window.innerHeight * .30;   // 70% up from bottom
    const progress = clamp01((scrollStart - turntableCenter) / ( scrollStart - scrollEnd));
    // console.log('handleScroll', turntableRect.bottom, turntableRect.top);
    setFrameIndex(Math.min(frameCount-1, Math.floor(progress * frameCount)));
  }, [  ]);

  useEffect(() => {
    if (inView) {
      // console.log('add scroll listener');
      window.addEventListener("scroll", handleScroll);
    } else {
      // console.log('remove scroll listener');
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      // console.log('remove scroll listener');
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inView, handleScroll]);

  useEffect(() => {
    // console.log(`frame ${(frameIndex + 1).toString().padStart(2, '0')} / ${frameCount}`, currentFrame(frameIndex));
    const context = canvasRef.current.getContext('2d');
    const img = new Image();
    img.src = currentFrame(frameIndex);
    img.onload = () => {
      // console.log('draw image', img.src);
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);
    }
  }, [ frameIndex ]);

  useEffect(() => {
    // preload images
    const loadImage = async (url) => {
      // NOTE: does not return anything; simply loads the image to prime the browser cache.
      // console.log('loading ...', url);
      const img = new Image();
      img.src = url;
      await img.decode();
      // console.log('loading ... DONE', url);
    }
    const imageUrls = [...Array(frameCount)].map((_, i) => (currentFrame(i)));
    // console.log('preload images ...', imageUrls)
    imageUrls.forEach((url) => {
      // Potential improvement: reorder the array so we fetch first - last - middle and so on, recursively.
      // That way on a slow device we'd get an approximation of the smooth carousel first.
      loadImage(url);
    });
    // console.log('preload images ... DONE',)
  }, []);

  return (
    <div className="turntable" ref={setRefs}>
      <canvas ref={canvasRef} id="turntable-canvas" width="1600" height="720" />
    </div>
  )
}

export default TurntableSmooth;
