import './FadeOut.css'

function FadeOut({children}) {
  return (
    <div className="fade-out">
      <div className='fade-out-children'>{children}</div>
    </div>
  )
}

export default FadeOut
